import axios from "axios";
import { getToken, removeToken } from "../misc/Cookies";
import router from "../../routes/routes";

/**
 * @return { import("axios").AxiosInstance }
 */

const instanceAxios = axios.create({
  baseURL: process.env.VUE_APP_CMS_URL + '/api/',
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`
  },
  timeout: 1000 * ( +process.env.VUE_APP_CMS_TIMEOUT || 40 ), // default 12 seconds
  timeoutErrorMessage: "please check your connection"
})

export default class CMSInternalAPI {
  constructor() {
    /** @type { import("axios").AxiosInstance } */
    this.$axios = instanceAxios

    this.$axios.interceptors.response.use(
      response => {
        return response
      },

      error => {
        if(error.response.status === 401) {
          removeToken()
          router.push('/login')
          return error.response.data
        }
        return error
      }
    )
  }


  /**
   *
   * @param error {object|null|unknown}
   * @return { string | unknown }
   */

  setErrorMessage(error) {
    if( error?.errors && error?.errors?.length > 1) {
      return error.errors
    } else if (error) {
      return error
    } else {
      return 'something went wrong'
    }
  }
}
